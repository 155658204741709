body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  background-color: #1e65ff;
  padding: 18px;
}

header h1 {
  color: white;
  max-width: 1200px;
  margin: 0 auto;
}

h2 {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 100px;
}

.profile-wrapper {
  margin: 20px auto;
  padding: 10px 15px;
  border-radius: 5px;
}

.profile {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 30px;
}
